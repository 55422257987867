import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const HarvestProductSectionStyled = styled.section.attrs({
  className: 'HarvestProductSectionStyled',
})`
  .ContentSection {
    background-color: white;
    padding: 3rem 1rem;

    @media ${breakPoints.medium_break} {
      .SectionInner {
        max-width: 33.5rem;
        margin: auto;
      }
    }

    @media ${breakPoints.large_break} {
      padding: 5rem 2rem;

      .SectionInner {
        max-width: 62rem;
      }
    }
  }
`;

export const HarvestProducInner = styled.div.attrs({
  className: 'HarvestProducInner',
})`
  .Title {
    margin-bottom: 0.5rem;
    font-size: 2.25rem;
    letter-spacing: normal;
  }

  .Text {
    line-height: 1.4rem;
    font-weight: lighter;
    max-width: 45rem;
    margin: auto;
  }

  @media ${breakPoints.large_break} {
    > .Title {
      font-size: 3rem;
    }
  }
`;

export const ZipCodeWrapper = styled.div.attrs({
  className: 'ZipCodeWrapper',
})`
  margin-top: 1rem;

  .ZipCode {
    width: 22rem;
    margin: auto;
  }
`;

export const EnvToggle = styled.div.attrs({
  className: 'EnvToggle',
})`
  background-color: ${({ theme }) => theme.farmProject.gray_3};
  max-width: 20rem;
  padding: 1.25rem 1.5rem;
  margin: 1rem auto 2rem;

  .GrowEnvironmentToggleStyled {
    .ToggleStyled {
      max-width: 20rem;

      .ToggleInner {
        .ButtonGroup {
          background-color: ${({ theme }) => theme.farmProject.gray_3};
        }
      }
    }
  }
`;

export const ProductFeaturesWrapper = styled.div.attrs({
  className: 'ProductFeaturesWrapper',
})`
  margin: 1rem 0 0.5rem;

  .Text {
    text-align: left;
    padding-left: 0.75rem;
    position: relative;
  }
`;

export const LabelWrapper = styled.div.attrs({
  className: 'LabelWrapper',
})`
  .Text {
    line-height: normal;
  }
`;
